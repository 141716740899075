export default {
  setting: 'Setting',
  send: 'Send',
  copy: 'Copy',
  hint: 'Hint',
  copySuccess: 'Copy successful',
  copyFail: 'Copy failed',
  connecting: 'Connecting',
  accountIsInexistence: 'Account information does not exist, please contact the administrator',
  systemError: 'System error, please try again later',
  authenticationFailed: 'User authentication failed, please log in again',
  translate: 'Translate',
  withdraw: 'Withdraw',
  edit: 'Edit',
  edited: 'Edited',
  translating: 'Translating',
  translateFail: 'Translation failed',
  result: 'Result',
  translationNotResult: 'No translation result, please check if the text is entered correctly',
  you: 'You',
  otherSide: 'Other side',
  withdrawnAMessage: 'Withdrew a message',
  serviceLanguage: 'Service language',
  selectServiceLanguage: 'Please select the required language type',
  automaticTranslation: 'Automatic translation',
  automaticTranslationTip: 'Messages automatically translated into the selected language',
  automaticTranslationTip2:
    "If 'Automatic Translation' is off, you can also activate the translation feature by long-pressing the message.",
  connectionSucceeded: 'Connection successful',
  connectionSucceededTip:
    "Server connection successful, the system has enabled new message sound reminders/disconnection sound reminders for you (please make sure your device's audio volume is turned on)",
  disconnectionReminder: 'Disconnection reminder',
  disconnectionReminderTip:
    'You are currently disconnected, please check if your network is normal, if the network is normal please try refreshing the browser, if the connection still fails, please contact the platform.',
  photo: 'Photo',
  camera: 'Camera',
  voice: 'Voice'
}
