import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
const env: 'dev' | 'uat' | 'prod' = process.env.VUE_APP_NAME
import { showNotify } from 'vant'
import { Notice } from 'view-ui-plus'
import store from '@/store'
import globalConfig from '@/config/index'
import { EventBus } from '@/eventBus'
import { getStorage, isEmptyObject, logout, encipherString, decryptAES } from '@/libs/utils'
import type { Router } from 'vue-router'
const aesConfig = globalConfig.aesConfig[env]
let router: Router

const requestListTime: any = {}

/**
 * @description: 请求的config的类型
 * @showLoading: 是否显示loading，
 * @dataType: 数据类型
 * @showNotify: 是否显示错误通知
 * @customHeaders: 自定义头
 * @return {*}
 */
interface MyAxiosRequestConfig extends AxiosRequestConfig {
  showLoading?: boolean
  dataType?: string
  showNotify?: boolean
  customHeaders?: any
}

interface MyInternalAxiosRequestConfig extends InternalAxiosRequestConfig {
  showLoading?: boolean
  dataType?: string
  showNotify?: boolean
  customHeaders?: any
}

class HttpRequest {
  private axiosInstance: AxiosInstance

  constructor(baseUrl: string) {
    this.axiosInstance = axios.create({
      baseURL: baseUrl,
      // 请求超时时间（毫秒）
      timeout: 1000000
    })

    this.interceptors()
  }

  private interceptors(): any {
    this.axiosInstance.interceptors.request.use(
      (config: MyInternalAxiosRequestConfig) => {
        const requestUrl = config.url + 'method' + config.method
        if (config.showLoading) {
          // 请求超过 globalConfig.delayShowLoading 毫秒还没有返回数据时显示app-loading-toggle
          const timeId = setTimeout(() => {
            EventBus.emit('app-loading-toggle', true)
          }, globalConfig.delayShowLoading)
          requestListTime[requestUrl] = timeId
        }

        if (config.method === 'post' && !isEmptyObject(config.data)) {
          if (config.dataType && config.dataType === 'formData') {
            const bodyFormData = new FormData()
            for (const key in config.data) {
              if (Object.prototype.hasOwnProperty.call(config.data, key)) {
                const element = config.data[key]
                bodyFormData.append(key, element)
              }
            }
            config.data = bodyFormData
          }
        }

        if (config.url !== '/admin/login') {
          // 这个接口不放token
          const token = store.state.token
          if (token) {
            config.headers['authorization'] = 'Bearer ' + token
          }
        }

        // 添加语言
        config.headers.language = getStorage('language') || 'zh-CN'

        if (config.customHeaders) {
          Object.assign(config.headers, config.customHeaders)
        }

        // 接口传参加密
        if (aesConfig.open) {
          if (config.method === 'post' && !isEmptyObject(config.data)) {
            config.data = {
              aesPostData: encipherString(config.data)
            }
          } else if (config.method === 'get' && !isEmptyObject(config.params)) {
            config.params = {
              aesPostData: encipherString(config.params)
            }
          }
        }
        return config
      },
      error => {
        return Promise.reject(error)
      }
    )

    this.axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => {
        const requestUrl = response.config.url + 'method' + response.config.method
        if ((response.config as MyAxiosRequestConfig).showLoading) {
          if (requestListTime[requestUrl]) {
            clearTimeout(requestListTime[requestUrl])
            delete requestListTime[requestUrl]
          }
          EventBus.emit('app-loading-toggle', false)
        }
        // 解密
        if (aesConfig.open) {
          response.data = decryptAES(response.data)
        }
        console.log(response.config.url + '返回结果:', response)

        if (response.data.code === 200) {
          // 在响应数据返回前可以添加全局的响应处理
          return response.data
        } else {
          if ((response.config as MyAxiosRequestConfig).showNotify) {
            if (store.getters['_getScreenType'] === 'big') {
              Notice.warning({
                title: '提醒',
                desc: response.data.message
              })
            } else {
              showNotify({
                type: 'danger',
                message: response.data.message,
                className: 'request-notify'
              })
            }
          }

          if (response.data.code === 401 && !globalConfig.debug) {
            logout(router)
          }
          return Promise.reject(response.data.message)
        }
      },
      error => {
        console.log('error:', error)

        if ((error.config as MyAxiosRequestConfig).showLoading && store.state.appLoadingStatus) {
          EventBus.emit('app-loading-toggle', false)
        }
        const requestUrl = error.config.url + ':method:' + error.config.method
        if (requestListTime[requestUrl]) {
          clearTimeout(requestListTime[requestUrl])
        }
        if (error.response.status === 401 && !globalConfig.debug) {
          logout(router)
        }
        if ((error.response.config as MyAxiosRequestConfig).showNotify && error.response.data.message) {
          if (store.getters['_getScreenType'] === 'big') {
            Notice.warning({
              title: '提醒',
              desc: error.response.data.message
            })
          } else {
            showNotify({
              type: 'danger',
              message: error.response.data.message,
              className: 'request-notify'
            })
          }
        }
        return Promise.reject(error)
      }
    )
  }
  // 发起请求
  public request(options: MyAxiosRequestConfig): Promise<any> {
    return this.axiosInstance(options)
  }
  // 设置router
  static setRouter(isRouter: Router) {
    router = isRouter
  }
}

export default HttpRequest
