import { createStore } from 'vuex'
import { getStorage, setStorage } from '@/libs/utils'
import { SERVER_APIS } from '@/apis/server-apis'

interface KeyToObject {
  [key: string]: any
}

interface State {
  isApp: boolean
  keyboardHeight: number
  currentSystemType: string
  currentLanguage: string
  ifAutoTranslate: boolean
  screenType: string
  appLoadingStatus: boolean
  importEndThemeList: any[]
  token?: string
  thirdPartyToken?: string
  currentUserInfo?: any
  currentConversation: any
  socketClient: any
  rightClickMsgData: any
  isReconnection: boolean
  companySettingInfo: KeyToObject
  userSettingInfo: KeyToObject
}

export default createStore<State>({
  state: {
    isApp: false,
    keyboardHeight: 0,
    /**
     * @name 当前登录系统类型
     * @default 默认值为1
     * @description 1、客服账号或者客服用户端，2、平台账号
     */
    currentSystemType: getStorage('currentSystemType') || 1,
    currentLanguage: getStorage('currentLanguage') || navigator.language,
    ifAutoTranslate: getStorage('ifAutoTranslate') || false,
    screenType: 'big',
    importEndThemeList: [],
    appLoadingStatus: false,
    token: getStorage('token') || null,
    thirdPartyToken: getStorage('thirdPartyToken') || null,
    currentUserInfo: getStorage('currentUserInfo') || null,
    // 当前打开消息的用户信息
    currentConversation: {},
    socketClient: null,
    rightClickMsgData: null,
    isReconnection: false,
    companySettingInfo: getStorage('companySettingInfo') || {},
    userSettingInfo: getStorage('userSettingInfo') || {}
  },
  getters: {
    _getCurrentIsApp: state => state.isApp,
    _getKeyboardHeight: state => state.keyboardHeight,
    _getCurrentSystemType: state => state.currentSystemType,
    _getCurrentLanguage: state => state.currentLanguage,
    _getIfAutoTranslate: state => state.ifAutoTranslate,
    _getScreenType: state => state.screenType,
    _getImportEndThemeList: state => state.importEndThemeList,
    _getToken: state => state.token,
    _getThirdPartyToken: state => state.thirdPartyToken,
    _getCurrentUserInfo: state => state.currentUserInfo,
    // 获取当前打开消息的用户信息
    _getCurrentConversation: state => state.currentConversation,
    // SocketClient
    _getSocketClient: state => state.socketClient,
    _getRightClickMsgData: state => state.rightClickMsgData,
    _getIsReconnection: state => state.isReconnection,
    _getCompanySettingInfo: state => state.companySettingInfo,
    _getUserSettingInfo: state => state.userSettingInfo
  },
  mutations: {
    setCurrentIsApp(state, isApp) {
      state.isApp = isApp
    },
    setKeyboardHeight(state, height) {
      state.keyboardHeight = height
    },
    setCurrentSystemType(state, type) {
      state.currentSystemType = type
      setStorage('currentSystemType', type)
    },
    setCurrentLanguage(state, language) {
      state.currentLanguage = language
      setStorage('currentLanguage', language)
    },
    setIfAutoTranslate(state, ifAutoTranslate) {
      state.ifAutoTranslate = ifAutoTranslate
      setStorage('ifAutoTranslate', ifAutoTranslate)
    },
    setScreenType(state, screenWidth) {
      if (screenWidth > 750) {
        state.screenType = 'big'
      } else {
        state.screenType = 'small'
      }
    },
    setImportEndThemeList(state, themeList) {
      state.importEndThemeList = themeList
    },
    setToken(state, token) {
      state.token = token
      setStorage('token', token)
    },
    setThirdPartyToken(state, thirdPartyToken) {
      state.thirdPartyToken = thirdPartyToken
      setStorage('thirdPartyToken', thirdPartyToken)
    },
    setCurrentUserInfo(state, userInfo) {
      state.currentUserInfo = userInfo
      setStorage('currentUserInfo', userInfo)
    },
    // 设置当前会话信息
    setCurrentConversation(state, info) {
      state.currentConversation = info
    },
    // 设置socket实例
    setSocketClient(state, socket) {
      state.socketClient = socket
    },
    // 设置右键某个信息时的数据
    setRightClickMsgData(state, msg) {
      state.rightClickMsgData = msg
    },
    // 设置是否显示转接
    setIsReconnection(state, flag) {
      state.isReconnection = flag
    },
    // 存储企业的系统设置
    setCompanySetting(state, info: any) {
      state.companySettingInfo[info['key']] = info['val']
      setStorage('companySettingInfo', state.companySettingInfo)
    },
    //存储用户设置
    setUserSetting(state, info: any) {
      state.userSettingInfo[info['key']] = info['val']
      setStorage('userSettingInfo', state.userSettingInfo)
    }
  },
  actions: {
    // 根据key获取企业设置
    async getCompanySetting({ commit }, key) {
      const companySettingRes = await SERVER_APIS.getCompanySetting({
        key: key
      })
      if (companySettingRes.data.rows[0]) {
        commit('setCompanySetting', { key, val: companySettingRes.data.rows[0].val })
      }
    },
    // 根据key获取用户设置
    async getUserSetting({ commit }, key) {
      const userSettingRes = await SERVER_APIS.getServerUserSetting({
        key: key
      })
      if (userSettingRes.data.rows[0]) {
        commit('setUserSetting', { key, val: userSettingRes.data.rows[0] })
      }
    }
  },
  modules: {}
})
