import { createI18n } from 'vue-i18n'
import { Locale } from 'vant'
import { getStorage } from '@/libs/utils'

import zhCN from 'view-ui-plus/dist/locale/zh-CN'
import enUS from 'view-ui-plus/dist/locale/en-US'
import zhTW from 'view-ui-plus/dist/locale/zh-TW'
import viVN from 'view-ui-plus/dist/locale/vi-VN'

// 引入简体中文语言包
import vantZhCN from 'vant/es/locale/lang/zh-CN'
// 引入繁体中文语言包
import vantZhHK from 'vant/es/locale/lang/zh-HK'
// 引入英文语言包
import vantEnUS from 'vant/es/locale/lang/en-US'
// 引入越南语言包
import vantViVN from 'vant/es/locale/lang/vi-VN'

// 自定义的语言包
import customZhCN from './locales/zh-CN'
import customZhTW from './locales/zh-TW'
import customEnUS from './locales/en-US'
import customViVN from './locales/vi-VN'

// 获取用户缓存中设置的语言
// 如果缓存中没有设置，则获取浏览器首选语言
const useLanguage = getStorage('currentLanguage') || navigator.language
// 默认简体中文
// const useLanguage = 'zh-CN'

console.log('useLanguage:', useLanguage)

const globalI18n = createI18n({
  legacy: false,
  // 默认语言
  locale: useLanguage,
  // 如果当前语言没有某个翻译，使用的语言
  fallbackLocale: 'en-US',
  messages: {
    'zh-CN': Object.assign(zhCN, customZhCN),
    'zh-TW': Object.assign(zhTW, customZhTW),
    'en-US': Object.assign(enUS, customEnUS),
    'vi-VN': Object.assign(viVN, customViVN)
  }
})

export const setVanTI18n = (lang: 'zh-CN' | 'zh-TW' | 'en-US' | 'vi-VN') => {
  if (lang === 'en-US') {
    Locale.use('en-US', vantEnUS)
  } else if (lang === 'zh-CN') {
    Locale.use('zh-CN', vantZhCN)
  } else if (lang === 'zh-TW') {
    Locale.use('zh-HK', vantZhHK)
  } else if (lang === 'vi-VN') {
    Locale.use('vi-VN', vantViVN)
  }
}
setVanTI18n(useLanguage)

export const i18n = globalI18n
