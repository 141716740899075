import store from '@/store'
import { Modal, Message } from 'view-ui-plus'
import { Dialog, showNotify, closeNotify, NotifyType } from 'vant'

function myBeforeClose(action: string, done: () => any, onOk: () => any) {
  if (action === 'confirm') {
    onOk()
      .then((res: any) => {
        console.log(res)
        done()
      })
      .catch((err: any) => {
        console.log(err)
        done()
      })
  } else {
    done()
  }
}

export interface MyConfirmConfig {
  title: string
  loading: boolean
  onOk: <T>() => Promise<string | T>
  onCancel: () => void
}

export const myConfirm = (config: MyConfirmConfig) => {
  if (store.getters['_getScreenType'] === 'big') {
    if (config.loading) {
      Modal.confirm({
        title: config.title,
        loading: config.loading,
        onOk: () => {
          config
            .onOk()
            .then(res => {
              console.log(res)
              Modal.remove()
            })
            .catch(err => {
              console.log(err)
              Modal.remove()
            })
        }
      })
    } else {
      Modal.confirm(config)
    }
  } else {
    if (config.loading) {
      Dialog.confirm({
        title: config.title,
        beforeClose: (action: string, done: () => void) => {
          myBeforeClose(action, done, config.onOk)
        }
      }).catch((err: any) => {
        console.log(err)
      })
    } else {
      Dialog.confirm(config)
        .then(() => {
          config.onOk().catch(err => {
            console.log(err)
          })
        })
        .catch(() => {
          config.onCancel()
        })
    }
  }
}

export interface MyMessageConfig {
  type: NotifyType | string
  message: string
  duration?: number
  onClose?: () => void
}

export const myMessage = (config: MyMessageConfig) => {
  if (store.getters['_getScreenType'] === 'big') {
    const _type = config.type === 'primary' ? 'info' : config.type === 'danger' ? 'error' : config.type
    Message[_type]({
      content: config.message ?? '',
      duration: config.duration || 1.5,
      onClose: config.onClose ?? undefined
    })
  } else {
    showNotify({
      type: config.type as NotifyType,
      message: config.message ?? '',
      duration: config.hasOwnProperty('duration') ? (config.duration as number) * 1000 : 3000,
      onClose: config.onClose ?? undefined
    })
  }
}

export const closeMyMessage = () => {
  if (store.getters['_getScreenType'] === 'big') {
    Message.destroy()
  } else {
    closeNotify()
  }
}
