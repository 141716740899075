export default {
  setting: 'cài đặt',
  send: 'Gửi',
  copy: 'Sao chép',
  hint: 'gợi ý',
  copySuccess: 'Sao chép thành công',
  copyFail: 'Sao chép thất bại',
  connecting: 'Đang kết nối',
  accountIsInexistence: 'Thông tin tài khoản không tồn tại, vui lòng liên hệ quản trị viên',
  systemError: 'Lỗi hệ thống, vui lòng thử lại sau',
  authenticationFailed: 'Xác thực người dùng thất bại, vui lòng đăng nhập lại',
  translate: 'Dịch',
  withdraw: 'Rút lại',
  edit: 'Chỉnh sửa',
  edited: 'Đã chỉnh sửa',
  translating: 'Đang dịch',
  translateFail: 'Dịch thất bại',
  result: 'Kết quả',
  translationNotResult: 'Không có kết quả dịch, vui lòng kiểm tra xem văn bản đã nhập đúng chưa',
  you: 'Bạn',
  otherSide: 'Bên kia',
  withdrawnAMessage: 'Đã rút lại một tin nhắn',
  serviceLanguage: 'Ngôn ngữ dịch vụ',
  selectServiceLanguage: 'Vui lòng chọn loại ngôn ngữ cần thiết',
  automaticTranslation: 'Dịch tự động',
  automaticTranslationTip: 'Tin nhắn tự động dịch sang ngôn ngữ đã chọn',
  automaticTranslationTip2:
    "Nếu 'Dịch tự động' bị tắt, bạn cũng có thể kích hoạt tính năng dịch bằng cách nhấn lâu vào tin nhắn.",
  connectionSucceeded: 'Kết nối thành công',
  connectionSucceededTip:
    'Kết nối máy chủ thành công, hệ thống đã bật nhắc nhở âm thanh tin nhắn mới / nhắc nhở âm thanh ngắt kết nối cho bạn (vui lòng đảm bảo âm lượng âm thanh của thiết bị đã bật)',
  disconnectionReminder: 'Nhắc nhở ngắt kết nối',
  disconnectionReminderTip:
    'Bạn hiện đang bị ngắt kết nối, vui lòng kiểm tra xem mạng của bạn có bình thường không, nếu mạng bình thường hãy thử làm mới trình duyệt, nếu kết nối vẫn thất bại, vui lòng liên hệ với nền tảng.',
  photo: 'Ảnh',
  camera: 'Máy ảnh',
  voice: 'Giọng nói'
}
