import HttpRequest from '@/libs/http-instance'
import globalConfig from '@/config/index'
const env: 'dev' | 'uat' | 'prod' = process.env.VUE_APP_NAME
const baseUrl: string = globalConfig.baseUrl[env]
const axiosServer: HttpRequest = new HttpRequest(baseUrl)
import type {
  CaptchaRes,
  CommApiRes,
  TranslateReq,
  TranslateRes,
  UploadFileReq,
  UploadFileRes
} from '@/types/apis-types/common-apis-types'
interface CommonApis {
  /**
   * 获取图片验证码接口
   * */
  getCaptcha(): Promise<CaptchaRes>
  /**
   * 翻译
   * */
  translate(data: TranslateReq): Promise<TranslateRes>
  /**
   * 谷歌翻译
   * */
  googleTranslate(data: TranslateReq): Promise<TranslateRes>
  /**
   * openAi翻译
   * */
  openAiTranslate(data: TranslateReq): Promise<TranslateRes>
  /**
   * 获取当前版本信息
   * */
  getCurrentVersionInfo(): Promise<CommApiRes>

  /**
   * 上传文件
   * */
  uploadFile(data: UploadFileReq, customHeaders?: any): Promise<UploadFileRes>
  /**
   * 上传文件的地址，用于view-ui-plus的upload组件
   * */
  uploadFileApiUri(): string
  /**
   * 上传文件到tg图床
   * */
  uploadFileToTelegram(data: UploadFileReq, customHeaders?: any): Promise<UploadFileRes>
  /**
   * 上传文件到tg图床的地址，用于view-ui-plus的upload组件
   * */
  uploadFileToTelegramApiUrl(): string
}

export const COMMON_APIS: CommonApis = {
  getCaptcha() {
    return axiosServer.request({
      url: '/comm/getCaptcha',
      method: 'get'
    })
  },
  translate(data) {
    return axiosServer.request({
      url: '/comm/translateText',
      method: 'post',
      data
    })
  },
  googleTranslate(data) {
    return axiosServer.request({
      url: '/comm/googleTranslateText',
      method: 'post',
      data
    })
  },
  openAiTranslate(data) {
    return axiosServer.request({
      url: '/comm/openAiTranslateText',
      method: 'post',
      data
    })
  },
  getCurrentVersionInfo() {
    return axiosServer.request({
      url: '/comm/getCurrentVersion',
      method: 'get'
    })
  },
  uploadFile(data, customHeaders) {
    return axiosServer.request({
      url: '/comm/uploadFile',
      method: 'post',
      data,
      customHeaders,
      dataType: 'formData',
      showNotify: true
    })
  },
  uploadFileApiUri() {
    return baseUrl + 'comm/uploadFile'
  },
  uploadFileToTelegram(data, customHeaders) {
    return axiosServer.request({
      url: '/comm/uploadFileToTelegram',
      method: 'post',
      data,
      customHeaders,
      dataType: 'formData',
      showNotify: true
    })
  },
  uploadFileToTelegramApiUrl() {
    return baseUrl + 'comm/uploadFileToTelegram'
  }
}
